
// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Font Awesome
@import "node_modules/font-awesome/scss/font-awesome";

//Slick-carousel
@import "node_modules/slick-carousel/slick/slick.scss";
@import "node_modules/slick-carousel/slick/slick-theme.scss";


/* ==========================================================================
   fonts
========================================================================== */

@font-face {
	font-family: 'Lora-Regular';
	src: url('../fonts/Lora-Regular/Lora-Regular.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/Lora-Regular/Lora-Regular.woff') format('woff'),
		 url('../fonts/Lora-Regular/Lora-Regular.ttf')  format('truetype'),
		 url('../fonts/Lora-Regular/Lora-Regular.svg#Lora-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Lora-Bold';
	src: url('../fonts/Lora-Bold/Lora-Bold.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/Lora-Bold/Lora-Bold.woff') format('woff'),
		 url('../fonts/Lora-Bold/Lora-Bold.ttf')  format('truetype'),
		 url('../fonts/Lora-Bold/Lora-Bold.svg#Lora-Bold') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Oswald-Regular';
	src: url('../fonts/Oswald-Regular/Oswald-Regular.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/Oswald-Regular/Oswald-Regular.woff') format('woff'),
		 url('../fonts/Oswald-Regular/Oswald-Regular.ttf')  format('truetype'),
		 url('../fonts/Oswald-Regular/Oswald-Regular.svg#Oswald-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Oswald-Light';
	src: url('../fonts/Oswald-Light/Oswald-Light.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/Oswald-Light/Oswald-Light.woff') format('woff'),
		 url('../fonts/Oswald-Light/Oswald-Light.ttf')  format('truetype'),
		 url('../fonts/Oswald-Light/Oswald-Light.svg#Oswald-Light') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Oswald-Bold';
	src: url('../fonts/Oswald-Bold/Oswald-Bold.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/Oswald-Bold/Oswald-Bold.woff') format('woff'),
		 url('../fonts/Oswald-Bold/Oswald-Bold.ttf')  format('truetype'),
		 url('../fonts/Oswald-Bold/Oswald-Bold.svg#Oswald-Bold') format('svg');
	font-weight: normal;
	font-style: normal;
}

/* ==========================================================================
   utils
========================================================================== */

.text-transform-none {
	text-transform: none !important;
}
.out-animation {
	opacity: 0;
	-webkit-transform: translateX(-80px);
	-moz-transform: translateX(-80px);
	transform: translateX(-80px);
	
	&.top-left,
	&.bottom-left {
		-webkit-transform: translateX(80px);
		-moz-transform: translateX(80px);
		transform: translateX(80px);
	}	

}
// .in-animation {
// 	opacity: 0;
// 	-webkit-transform: translateX(-80px);
// 	-moz-transform: translateX(-80px);
// 	transform: translateX(-80px);
	
// 	&.top-left,
// 	&.bottom-left {
// 		-webkit-transform: translateX(-80px);
// 		-moz-transform: translateX(-80px);
// 		transform: translateX(-80px);
// 	}	

// }

/* ==========================================================================
   site
========================================================================== */

body {
	font-family: 'Lora-Regular';
	background-color: transparent;
	color: $text;

	* {
		font-family: 'Lora-Regular';
	}
}
html,
body {
	height: 100%;
	// overflow-y: hidden;
}
// .scroll-wrapper {
// 	height: 100%;
// 	overflow-y: auto;	
// }
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Oswald-Regular';
	margin: 0;
}
label {
	font-family: 'Oswald-Regular';
}
.btn {
	font-family: 'Oswald-Regular';
}
.form-control {
	border-radius: 0px;
	box-shadow: none;
	border-color: white white $black white;

	&:focus {
		border-color: $black;	
	}
}
strong,
b,
.bold {
	font-family: 'Lora-Bold';
	color: $black;
}
#go-top-wrap {
	// position: relative;
	position: fixed;
	right: 0;
	left: 0;
	bottom: 0;
	background: #000;
	height: 0px;
	overflow: visible;
	z-index: 9;
	// display: none;
}
#go-top {
	width: 40px;
	height: 40px;
	text-align: center;
	display: block;
	border-radius: 50%;
	background: #a7d0d6;
	color: white;
	position: absolute;
	bottom: 10px;
	right: 10px;
	visibility: hidden;
	opacity: 0;
	-webkit-transition: opacity .3s 0s, visibility 0s .3s;
	-moz-transition: opacity .3s 0s, visibility 0s .3s;
	transition: opacity .3s 0s, visibility 0s .3s;


	&.go-top-is-visible {
		visibility: visible;
		opacity: 1;
	}
	&.go-top-fade-out {
		opacity: .5;
	}
	&:hover {
		opacity: 1;
	}
	.fa {
		font-size: 40px;
	}
}

/* ==========================================================================
   header
========================================================================== */

header {

	@media only screen  and (max-width: 767px) {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		z-index: 10;
	}
	@media only screen  and (min-width: 768px) {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		z-index: 100;
		background-color: rgba(#fff, 0.9);

		& + section {
			margin-top: 85px;
		}
	}
}

.logo-header {
	display: block;
	margin: auto;
	text-align: right;

	img {
		display: block;
		margin: 5px 0px;
		float: right;
		width: 115px;
		max-width: 100%;
		z-index: 200;
		position: relative;
	}
}
@media only screen  and (max-width: 767px) {
	.logo-header {

		img {
			width: auto;
			height: 50px;
			float: right;
		}
	}
	header {

			#nav-overlay {
				transition: all 0.8s ease;
				width: 100%;
				height: 0;
				opacity: 0;
			}
			.open {

				.open-inner {					
					position: fixed;
					top: 0;
					left: 0;
					width: 50px;
					height: 50px;
					display: block;
					cursor: pointer;
					transition: opacity 0.4s linear;
					z-index: 100;
				}

				// &:hover {
				// 	opacity: 0.8;
				// }
				span {
					display: block;
					float: left;
					clear: both;
					height: 4px;
					width: 40px;
					background-color: $brand-primary;
					position: absolute;
					right: 3px;
					top: 3px;
					overflow: hidden;
					transition: all 0.4s ease;
					border-radius: 10px;

					&:nth-child(1) {
						top: 10px;
						z-index: 9;
					}
					&:nth-child(2) {
						top: 20px;
					}
					&:nth-child(3) {
						top: 30px;
					}
				}
			}
		.sub-menu {
			transition: all 0.8s ease;
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			position: absolute;
			background-color: rgba($black, 0.54);
			// border-radius: 50%;
			z-index: 10;
			overflow: hidden;
			padding: 0;
			text-align: center;

			li {
				display: block;
				float: left;
				clear: both;
				height: auto;
				width: 100%;
				text-align: center;
				transition: all 0.4s ease;
				&:first-child {
					margin-top: 190px;
				}
				&:nth-child(1) {
					-webkit-transition-delay: 0.05s;
				}
				&:nth-child(2) {
					-webkit-transition-delay: 0.10s;
				}
				&:nth-child(3) {
					-webkit-transition-delay: 0.15s;
				}
				&:nth-child(4) {
					-webkit-transition-delay: 0.20s;
				}
				&:nth-child(5) {
					-webkit-transition-delay: 0.25s;
				}
				a {
					color: #fff;
					font-size: 20px;
					width: 100%;
					display: block;
					float: left;
					text-transform: uppercase;
					font-family: 'Oswald-Regular';
					line-height: 40px;
				}
			}
		}
		.oppenned {

			.sub-menu {
				opacity: 1;
				height: 400px;
				width: 400px;
			}
			span:nth-child(1) ,
			span:nth-child(3) {
				z-index: 100;
				transform: rotate(45deg);
				background-color: #fff;
			}
			span:nth-child(1) {
				transform: rotate(45deg) translateY(12px) translateX(12px);
			}
			span:nth-child(2) {
				background-color: transparent !important;
			}
			span:nth-child(3) {			
				transform: rotate(-45deg) translateY(-3px) translateX(3px);
			}
			li {
				margin-left: 200px;
			}
			& + #nav-overlay {
				position: fixed;
				top: 0;
				right: 0;
				left: 0;
				bottom: 0;
				z-index: 10;
				width: 100%;
				height: 100%;				
				text-align: center;
				opacity: 1;
				background-color: rgba($brand-primary, .75);

				.sub-menu {
					border-radius: 0px;
				}
			}
		}
		.button{
			display: block;
			float: left;
			clear: both;
			padding: 20px 40px;
			background: #fff;
			border-radius: 3px;
			border: 2px solid #10a1ea;
			overflow: hidden;
			position: relative;

			&:after {
				transition: transform 0.3s ease;
				content: "";
				position: absolute;
				height: 200px;
				width: 400px;
				transform: rotate(45deg) translateX(-540px) translateY(-100px);
				background: #10a1ea;
				z-index: 1;
			}
			&:before {
				transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
				content: attr(title);
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				color: #fff;
				z-index: 2;
				text-align: center;
				padding: 20px 40px;
				transform: translateY(200px);
			}
			&:hover {
				text-decoration: none;

				&:after {
					transform: translateX(-300px) translateY(-100px);
				}
				&:before {
					transform: translateY(0);
				}
			}
		}
	}
}
.header-nav {
	display: inline-block;
	margin: auto;

	li {
		float: left;
		min-width: 150px;
		text-align: center;
	}
	a {
		font-size: 16px;
		font-family: 'Oswald-Regular';
		text-transform: uppercase;

		&:hover,
		&:active,
		&:focus {
			background-image: url('../img/bg-header-link.png');
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			background-color: transparent !important;
			color: #fff;
			outline: none;
		}
	}

	@media only screen  and (min-width: 768px) {
		li {
			line-height: 82px;
			height: 75px;
		}
	}
	@media only screen  and (min-width: 768px) and (max-width: 991px) {
		li {
			min-width: 0;
		}
	}
}

/* ==========================================================================
   footer
========================================================================== */

#footer-bg {
	max-width: 100%;
	display: block;
	margin: auto;
}

/* ==========================================================================
   home
========================================================================== */

.home-section {
	font-size: 16px;
	position: relative;
	padding-bottom: 150px;

	@media only screen and (max-width: 767px) {
		padding-bottom: 50px;
	}

	> div {
		position: relative;
		z-index: 2;
	}
	&:nth-child(even) {

		.home-title {
			text-align: right;

			h2 {
				background-image: url('../img/bg-home-title-right.png');

			}
		}
	}

	.watermark {
		text-transform: uppercase;
		color: rgba($brand-primary, 0.3);
		font-family: 'Oswald-Regular';
		font-size: 150px;
		display: block;
		margin: 0;
		position: absolute;
		-webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
		-moz-transition: -moz-transform 0.5s, opacity 0.5s;
		transition: transform 0.5s, opacity 0.5s;

		&.top-left {
			top: 0;
			left: 0;
		}
		&.top-right {
			top: 0;
			right: 0;
		}
		&.bottom-left {
			left: 0;
			bottom: 0;
		}
		&.bottom-right {
			right: 0;
			bottom: 0;
		}
		@media only screen and (max-width: 767px) {
			display: none;
		}
	}
}
.home-title {
	margin: 20px 0;

	span {
		color: $text;
		font-size: 25px;
		display: block;
		margin: 0 60px -20px;
		font-family: 'Oswald-Regular';
	}
	h2 {
		color: #fff;
		font-size: 50px;
		text-transform: uppercase;
		padding: 20px 60px;
		display: inline-block;
		background: transparent url('../img/bg-home-title.png') no-repeat center;
		background-size: contain;

	}
	@media only screen and (max-width: 767px) {

		h2 {
			font-size: 30px;
		}
		span {
			font-size: 20px;
		}
	}
}

/* section-break
========================================================================== */

.section-break {
	margin-top: 30px;
	margin-bottom: 30px;
	position: relative;
	
	&.row {
		margin-right: 0;
	}
	.break-text-bg {
		max-width: 100%;
		display: block;
		margin: auto;
	}
	.break-text-wrap {
		position: relative;
	}
	.break-text {
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
		right: 0;
		margin: auto;

		span {
			font-family: 'Oswald-Bold' !important;
			text-transform: uppercase;
			font-size: 30px;
			color: $black;
			width: 100%;
			display: block;
			margin: auto;
		}
		strong {
			font-family: 'Oswald-Bold' !important;
			color: $highlight-orange;
		}
	}

	@media only screen and (max-width: 991px) {
		&.row {
			margin: 30px 0;			
		}
	}
}

/* jumbotron
========================================================================== */
#jumbotron {
	position: relative;

	img {
		max-width: 100%;
	}
	.container {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	@media only screen  and (max-width: 767px) {
		min-height: 100%;
	}
}
.jumbotron-highlight {
	position: absolute;
	width: 100%;
	top: 100px;
	right: 0;
	height: 116px;
	margin: auto;

	.eyebrow {
		width: 100px;
		height: 4px;
		background-color: $highlight-yellow;
		float: right;
	}
	span {
		color: $black;
		display: block;
		width: 100%;
		text-align: right;
		clear: both;
		font-size: 35px;
	}
	strong {
		color: $highlight-yellow;
	}

	@media only screen  and (max-width: 767px) {
		right: 15px;
		height: 70px;
		bottom: 0;
		top: auto;

		span {
			font-size: 20px;
		}
	}
}

/* nosotros
========================================================================== */

#home-nosotros {

	.section-break {

		.container {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
		}
		.break-text {
			width: 100%;
			height: 240px;
			bottom: 0;
			text-align: center;
		}
	}

	@media only screen and (max-width: 991px) {
		.section-break {
			.container {
				position: relative;
			}
		}
	}
}
#home-nosotros-main-img {
	display: block;
	margin: 0;
	max-width: 100%;
}

/* estrategia
========================================================================== */

#home-estrategia {

	.section-break {

		.break-text {
			text-align: center;
			padding: 35px;
		}
	}

	@media only screen and (max-width: 767px) {
		.break-text {
			padding: 10px !important;

			span {
				font-size: 30px;
			}
		}
	}
}
.estrategia {
	text-align: center;
	
	&:nth-child(even) {
		
		.estrategia-title {
			color: $title-lochmara;
		}
	}
	img {
		max-width: 100%;
		display: block;
		margin: auto;
	}
	.estrategia-title {
		font-family: 'Oswald-Light';
		color: $title-lipstick;
		font-size: 25px;
		padding: 30px 0;
		display: block;
	}
}

/* clientes
========================================================================== */

.client {
	img {
		display: block;
		margin: auto;
		max-width: 100%;
	}
}

/* equipo
========================================================================== */

.teammate-avatar-wrap {
	position: relative;
}
.teammate-avatar-white-wrap {
	width: 75%;
	height: 75%;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	overflow: hidden;
}
.teammate-avatar-white {
	max-width: 100%;
	display: block;
	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.teammate-avatar-white-mask {
	width: 100%;
	height: 100%;
	background-color: rgba(255,255,255,0.75);
	position: relative;
}
.teammate-avatar {
	max-width: 100%;
	display: block;
	margin: auto;
	-webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
	clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}
.teammate-info {
	border-bottom: 1px solid $brand-primary;
	padding: 40px 0 20px;
}
.teammate-eyebrow {
	width: 100px;
	height: 4px;
	display: block;
	margin: 20px 0 0;
	background-color: $highlight-yellow;
}
.teammate-name-wrap {
	
	h3 {
		margin: 10px 0;
	
		span {
			text-transform: uppercase;
			font-family: 'Oswald-Regular';
		}
	}
	.teammate-name {
		color: $denim;
	}
	.teammate-lastname {
		color: $black;
	}
}
.teammate-social {
	text-transform: capitalize;
}

/* contacto
========================================================================== */

.home-contacto-form {
	margin-bottom: 30px;
}
.contacto-info {

	span {
		display: block;
		width: 100%;
		margin: auto;
	}
	i {
		margin-right: 5px;
	}
}
.map-wrap {
	text-align: center;
	position: relative;
}
#main-map {
	width: 100%;
	height: 300px;
	max-width: 100%;
	margin: auto;
}
